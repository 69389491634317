
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from "./Component/Page/Homepage"
import Services from "./Component/Page/Services"
import Blog from "./Component/Page/Blog" 
import Contact from "./Component/Page/Contact"
import About from './Component/Page/About';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
