import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom"
import { Fragment } from "react";
import Counter from './Counter';
import ClientsFeedback from "../Page/ClientsFeedback";
import Hero1 from "../Page/Hero1";
import Layout from "../../Component/layout/Layout";
const Homepage = () => {
  const [isCrmHovered, setIsCrmHovered] = useState(false);
  const [isIbHovered, setIsIbHovered] = useState(false);
  const [isForexHovered, setIsForexHovered] = useState(false);
  const [isWebHovered, setIsWebHovered] = useState(false);
  return (
    <Fragment>
      <Helmet>
        <Link rel="shortcut icon" href="/assets/images/favicon1.png" type="image/x-icon" />
      </Helmet>
      <Layout header={1} className="home-one" footer={1}>

        <Hero1 />


        {/* <section className="feature-area-five bgc-lighter pt-100 pb-70">
          <div className="container">
            <div className="section-title text-center mb-10 wow fadeInUp delay-0-2s">
              <span className="sub-title mb-1">
                OUR SERVICES
              </span>
              <h2><b>Forex Solutions</b></h2>
            </div>
            <div className="row row-cols-xl-6 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 justify-content-center">
              <div className="col wow fadeInUp delay-0-2s">
                <div className="feature-item-five">
                  <i className="flaticon-responsive" />
                  <h5>
                    <Link to="/service-details">
                      <a className='service-title'>Forex Trader Room</a>
                    </Link>
                  </h5>
                </div>
              </div>
              <div className="col wow fadeInUp delay-0-3s">
                <div className="feature-item-five">
                  <i className="flaticon-feature" />
                  <h5>
                    <Link to="/service-details">
                      <a className='service-title'>Multilevel IB
                      </a>
                    </Link>
                  </h5>
                </div>
              </div>
              <div className="col wow fadeInUp delay-0-4s">
                <div className="feature-item-five">
                  <i className="flaticon-aim" />
                  <h5>
                    <Link to="/service-details">
                      <a className='service-title'>Forex Web Design</a>
                    </Link>
                  </h5>
                </div>
              </div>
              <div className="col wow fadeInUp delay-0-5s">
                <div className="feature-item-five">
                  <i className="flaticon-seo" />
                  <h5>
                    <Link to="/service-details">
                      <a className='service-title'>CRM Solutions</a>
                    </Link>
                  </h5>
                </div>
              </div>

            </div>
          </div>
        </section> */}
        <section className="feature-area-five bgc-lighter pt-100 pb-70">
          <div className="container">
            <div className="row">

              <div className="col-md-6 d-flex flex-column justify-content-center">
                {/* <h5 style={{ color: '#14AEE5' }} ><b>OUR SERVICES</b></h5> */}
                <h2 className='mt-3'><b>Forex Solutions</b></h2>
                <div className="fs-5 mt-3" style={{ width: '80%' }}>
                  Being a trusted IT software Development entity in USA, UK, Canada, UAE, India. We believe in delivering out of the box solutions.
                </div>

                <a className="theme-btn mt-5 w-50">
                  View More
                  <i className="fas fa-angle-double-right" />
                </a>
              </div>

              <div className="col-md-6">
                <div className='row'>
                  <div className="col ">
                    <div className='mt-5' >
                      <div className="service-card">
                        {/* <i className="flaticon-responsive" /> */}
                        <div>
                          <img src="/assets/images/forextrader1.png" height={70} width={70} style={{ objectFit: 'contain' }} className="mb-4 absolute top-0 left-0" alt="img" />
                        </div>
                        <p className='fs-5 fw-semibold fs-4'>Forex Trader Room</p>
                        <p>
                          Automate business services and operation with innovative IT solutions.
                        </p>

                      </div>
                    </div>
                    <div onMouseEnter={() => setIsWebHovered(true)} onMouseLeave={() => setIsWebHovered(false)} >
                      <div className="feature-item-five card-text">
                        {/* <i className="flaticon-aim" /> */}
                        <div>
                          {
                            isWebHovered ?
                              (<img src="/assets/images/forexwebdesign1.png" style={{ objectFit: 'contain' }} height={70} width={70} className="mb-4 absolute top-0 left-0" alt="img" />) :
                              (<img src="/assets/images/forexwebdesign.png" style={{ objectFit: 'contain' }} height={70} width={70} className="mb-4 absolute top-0 left-0" alt="img" />)
                          }
                        </div>
                        <h5 className='mb-3'>
                          <Link to="/service-details">
                            <a className='service-title fs-4'>Forex Web Design</a>
                          </Link>
                        </h5>
                        <p className="card-text">
                          Automate business services and operation with innovative IT solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col ">
                    <div onMouseEnter={() => setIsIbHovered(true)} onMouseLeave={() => setIsIbHovered(false)} >
                      <div className="feature-item-five card-text">
                        {/* <i className="flaticon-feature" /> */}
                        <div>
                          {
                            isIbHovered ?
                              (<img src="/assets/images/multilevelib1.png" style={{ objectFit: 'contain' }} height={70} width={70} className="mb-4 absolute top-0 left-0" alt="img" />) :
                              (<img src="/assets/images/multilevelib.png" style={{ objectFit: 'contain' }} height={70} width={70} className="mb-4 absolute top-0 left-0" alt="img" />)
                          }
                        </div>
                        <h5 className='mb-3'>
                          <Link to="/service-details">
                            <a className='service-title fs-4'>Multilevel IB
                            </a>
                          </Link>
                        </h5>
                        <p className="card-text">
                          Automate business services and operation with innovative IT solutions.
                        </p>
                      </div>
                    </div>
                    <div className='mt-4'>
                      <div className="service-card">
                        {/* <i className="flaticon-seo" /> */}
                        <div>
                          <img src="/assets/images/crmsolution1.png" style={{ objectFit: 'contain' }} height={70} width={70} className="mb-4 absolute top-0 left-0" alt="img" />
                        </div>
                        <p className='fw-semibold fs-4'>CRM Solutions</p>
                        <p className='fs-6'>
                          Automate business services and operation with innovative IT solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Core Feature end */}
        {/* About Us Area start */}
        <section className="about-area-one pt-130 pb-125 rel z-1">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-5">
                <div className="about-image-one bg-squire-shape rmb-85 wow fadeInUp delay-0-2s">
                  <img src="/assets/images/about/about-one.jpg" alt="About us" />
                  <img
                    className="image-left"
                    src="/assets/images/shapes/image-left.png"
                    alt="shape"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-7">
                <div className="about-content-one wow fadeInUp delay-0-4s">
                  <div className="section-title mb-10">
                    {/* <span className="sub-title mb-1">
                      ABOUT US
                    </span> */}
                    <h2><b>Grow Your Business</b></h2>
                  </div>
                  <ul className="list-style-one" style={{ padding: '0px' }}>
                    <li>
                      <div className="content">
                        {/* <h4>Company Mission</h4> */}
                        <p>
                          Every website that we create is designed and built by our team of friendly web designers and you have full content management so that you can update your website whenever you wish.
                        </p>
                        {/* <Link href="/pricing"> */}
                        <a className="theme-btn mt-3">
                          Get In Touch
                          <i className="fas fa-angle-double-right" />
                        </a>
                        {/* </Link> */}
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="statistics-area-three bgs-cover pb-100 rel z-1">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-7">
                <div className="statistics-three-content rmb-65 wow fadeInRight delay-0-2s">
                  <div className="row justify-content-center justify-content-xl-start">
                    <div className="col-xl-9">
                      <div className="section-title mb-10">
                        {/* <span className="sub-title mb-1">
                          COMPANY STATISTICS
                        </span> */}
                        <h2><b>We’ve some achievement from global partners</b></h2>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                      <div className="counter-item style-two counter-text-wrap wow fadeInRight delay-0-3s">
                        <i className="flaticon-startup" />
                        <Counter end={500} />
                        <span className="counter-title">Satisfied Clients</span>
                        {/* <p>On the other denonce with righteous indin</p> */}
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                      <div className="counter-item style-two counter-text-wrap wow fadeInRight delay-0-5s">
                        <i className="flaticon-global" />
                        <Counter end={90} />
                        <span className="counter-title">Repeat Business</span>
                        {/* <p>On the other denonce with righteous indin</p> */}
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                      <div className="counter-item style-two counter-text-wrap wow fadeInRight delay-0-7s">
                        <i className="flaticon-rating" />
                        <Counter end={10} />
                        <span className="counter-title">Years of Experience</span>
                        {/* <p>On the other denonce with righteous indin</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="statistics-three-image bg-squire-shape mb-30 wow fadeInLeft delay-0-3s">
                  <img
                    src="assets/images/about/statictics.jpg"
                    alt="Statictics"
                  />
                  <img
                    className="image-right"
                    src="assets/images/shapes/image-right.png"
                    alt="shape"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="design-feature-area overflow-hidden pt-130 pb-100 text-white bgc-black-with-lighting rel z-1">
          <div className="container">
            <div className="section-title text-center mb-30 wow fadeInUp delay-0-2s">
              {/* <span className="sub-title mb-1">LET'S GO LIVE</span> */}
              <h2><b>Web Based Solutions</b></h2>
            </div>
            <div className="row no-gap align-items-center">
              <div className="col-lg-3">
                <div className="feature-left">
                  <div className="row">
                    <div className="col-lg-12 col-sm-6">
                      <div className="service-item style-three wow fadeInRight delay-0-2s">
                        <div className="icon">
                          <i className="flaticon-design" />
                        </div>
                        <div className="content">
                          <h4>
                            <Link to="/service-details">
                              <a>Web Design Agency</a>
                            </Link>
                          </h4>
                          <Link to="/service-details">
                            <a className="more-btn">
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-6">
                      <div className="service-item style-three wow fadeInRight delay-0-3s">
                        <div className="icon">
                          <i className="flaticon-web-page" />
                        </div>
                        <div className="content">
                          <h4>
                            <Link to="/service-details">
                              <a>Branding Solutions</a>
                            </Link>
                          </h4>
                          <Link to="/service-details">
                            <a className="more-btn">
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-middle rmt-30">
                  <div className="image wow fadeInUp delay-0-2s">
                    <img
                      className="circle-text"
                      src="assets/images/shapes/feature-image-top.png"
                      alt="Text"
                    />
                    <img
                      className="round"
                      src="assets/images/features/feature-middle.png"
                      alt="Feature Middle"
                    />
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="service-item style-three wow fadeInUp delay-0-3s">
                        <div className="icon">
                          <i className="flaticon-online" />
                        </div>
                        <div className="content">
                          <h4>
                            <Link to="/service-details">
                              <a>Wordpress Development</a>
                            </Link>
                          </h4>
                          <Link to="/service-details">
                            <a className="more-btn">
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="service-item style-three mt-30 wow fadeInUp delay-0-4s">
                        <div className="icon">
                          <i className="flaticon-web-programming" />
                        </div>
                        <div className="content">
                          <h4>
                            <Link to="/service-details">
                              <a>SEO Services</a>
                            </Link>
                          </h4>
                          <Link to="/service-details">
                            <a className="more-btn">
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="feature-right">
                  <div className="row">
                    <div className="col-lg-12 col-sm-6">
                      <div className="service-item style-three mt-100 wow fadeInLeft delay-0-2s">
                        <div className="icon">
                          <i className="flaticon-graphic-design" />
                        </div>
                        <div className="content">
                          <h4>
                            <Link to="/service-details">
                              <a>Social Media Management</a>
                            </Link>
                          </h4>
                          <Link to="/service-details">
                            <a className="more-btn">
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-6">
                      <div className="service-item style-three wow fadeInLeft delay-0-3s">
                        <div className="icon">
                          <i className="flaticon-user-experience" />
                        </div>
                        <div className="content">
                          <h4>
                            <Link to="/service-details">
                              <a>Web Development</a>
                            </Link>
                          </h4>
                          <Link to="/service-details">
                            <a className="more-btn">
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="design-feature-shapes">
            <img
              className="shape dots"
              src="assets/images/shapes/slider-dots.png"
              alt="Shape"
            />
            <img
              className="shape wave-line"
              src="assets/images/shapes/feature-wave-line.png"
              alt="Shape"
            />
          </div>
        </section>


        <section className="feature-area-five bgc-lighter pt-100 pb-70">
          <div className="container">
            <div className="section-title text-center mb-10 wow fadeInUp delay-0-2s">
              {/* <span className="sub-title mb-1">OUR BENEFITS</span> */}
              <h2><b>Why Choose Us?</b></h2>
            </div>
            <div className="row row-cols-xl-2 row-cols-lg-2 row-cols-md-3 row-cols-sm-2 row-cols-1 justify-content-center">
              <div className="col wow fadeInUp delay-0-2s service-title ">
                <div className="feature-item-five">
                  <i className="flaticon-responsive" />
                  <h5>
                    <Link to="/service-details">
                      <a className='service-title '>Professional Service</a>
                    </Link>
                  </h5>
                  <span className="sub-title mb-15">Before we programme a word of code, our dedicated account manager will take a detailed brief from you.</span>
                </div>
              </div>
              <div className="col wow fadeInUp delay-0-3s service-title ">
                <div className="feature-item-five">
                  <i className="flaticon-feature" />
                  <h5>
                    <Link to="/service-details">
                    <a className='service-title '>Competitive Pricing</a>
                    </Link>
                  </h5>
                  <span className="sub-title mb-15">We offer the most competitive prices by instantly adapting to the demands and prices of our competitors.</span>
                </div>
              </div>
              <div className="col wow fadeInUp delay-0-4s service-title ">
                <div className="feature-item-five">
                  <i className="flaticon-aim" />
                  <h5>
                    <Link to="/service-details">
                    <a className='service-title '>Advanced Technology</a>
                    </Link>

                  </h5>
                  <span className="sub-title mb-15">we are proficient at the latest web technologies, and specialise in open-source platforms.</span>
                </div>
              </div>
              <div className="col wow fadeInUp delay-0-5s service-title ">
                <div className="feature-item-five">
                  <i className="flaticon-seo" />
                  <h5>
                    <Link to="/service-details">
                    <a className='service-title '>Flexible Approach</a>
                    </Link>
                  </h5>
                  <span className="sub-title mb-15">Our experts take a customer-centric approach to deliver bespoke technology solutions that meet your expectations, timelines and budget.</span>
                </div>
              </div>

            </div>
          </div>
        </section>


        <section className="work-process-area pb-95 rel z-1">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="section-title text-center mb-10 wow fadeInUp delay-0-2s pt-4">
                  {/* <span className="sub-title mb-1 mt-5">WORKING PROCESS</span> */}
                  <h2><b>How does we works</b></h2>
                </div>
              </div>
            </div>
            <div className="work-process-wrap rel z-1">
              <div className="row justify-content-between">
                <div className="col-xl-3 col-lg-5 col-sm-6">
                  <div className="work-process-item mt-30 wow fadeInUp delay-0-2s">
                    <div className="icon">
                      <span className="number">01</span>
                      <i className="flaticon-optimization" />
                    </div>
                    <h4>Info Gathering</h4>
                    <p>
                      Sit amet consectetur adipiscing elit sed eiusmod tempor
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5 col-sm-6">
                  <div className="work-process-item wow fadeInUp delay-0-4s">
                    <div className="icon">
                      <span className="number">02</span>
                      <i className="flaticon-link" />
                    </div>
                    <h4>Idea Planning</h4>
                    <p>
                      Sit amet consectetur adipiscing elit sed eiusmod tempor
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5 col-sm-6">
                  <div className="work-process-item mt-55 wow fadeInUp delay-0-6s">
                    <div className="icon">
                      <span className="number">03</span>
                      <i className="flaticon-data" />
                    </div>
                    <h4>Design Analysis</h4>
                    <p>
                      Sit amet consectetur adipiscing elit sed eiusmod tempor
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5 col-sm-6">
                  <div className="work-process-item mt-45 wow fadeInUp delay-0-8s">
                    <div className="icon">
                      <span className="number">04</span>
                      <i className="flaticon-star" />
                    </div>
                    <h4>Testing &amp; Lunch</h4>
                    <p>
                      Sit amet consectetur adipiscing elit sed eiusmod tempor
                    </p>
                  </div>
                </div>
              </div>
              <div className="work-process-shape">
                <img
                  src="assets/images/shapes/worp-process-step.png"
                  alt="Shape"
                />
              </div>
            </div>
          </div>
        </section>

        <ClientsFeedback />

        <section className="contact-area overflow-hidden py-130 bgc-black-with-lighting rel z-1 m">
          <div className="container">
            <div className="row justify-content-between ">
              <div className="col-xl-5 col-lg-6 p-1">
                <div className="contact-info-area text-white rmb-75 wow fadeInLeft delay-0-2s">
                  <div className="section-title mb-55">
                    <h2>
                      Have any project on mind! feel free contact with us or{" "}
                      <span>say hello</span>
                    </h2>
                  </div>
                  <div className="contact-info-wrap">
                    <div className="contact-info-item">
                      <div className="icon">
                        {/* <i className="fal fa-map-marker-alt" /> */}
                        <img src='/assets/images/location.svg' alt='' className='svg-icons' />
                      </div>
                      <div className="content">
                        <span className="title" >Location</span>
                        <span className="text" >38507/001,IFZA Business Park, Dubai Silicon, Oais, Dubai, UAE</span>
                      </div>
                    </div>
                    <div className="contact-info-item">
                      <div className="icon">
                        {/* <i className="far fa-envelope-open-text" /> */}
                        <img src='/assets/images/email.svg' alt='' className='svg-icons' />
                      </div>
                      <div className="content">
                        <span className="title">Email Address</span>
                        <b className="text" >
                          <a href="mailto:office@logiclinkfzco.com">
                            office@logiclinkfzco.com
                          </a>
                        </b>
                      </div>
                    </div>
                    <div className="contact-info-item">
                      <div className="icon">
                        {/* <i className="far fa-phone" /> */}
                        <img src='/assets/images/call.svg' alt='' className='svg-icons' />
                      </div>
                      <div className="content">
                        <span className="title">Phone No</span>
                        <b className="text" >
                          <a href="callto:+971 50 786 6593">+971-50 196 3589</a>
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  id="contact-area-form"
                  className="contact-area-form text-center wow fadeInRight delay-0-2s"
                  name="contact-area-form"
                  action="#"
                  method="post"
                >
                  <h2 className='mb-4'><b>Send us Message</b></h2>
                  <input
                    type="text"
                    id="full-name"
                    name="full-name"
                    className="form-control"
                    defaultValue=""
                    placeholder="Full Name"
                    required=""
                  />
                  <input
                    type="email"
                    id="blog-email"
                    name="blog-email"
                    className="form-control"
                    defaultValue=""
                    placeholder="Email Address"
                    required=""
                  />
                  <input
                    type="text"
                    id="website"
                    name="website"
                    className="form-control"
                    defaultValue=""
                    placeholder="Website"
                    required=""
                  />
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows={5}
                    placeholder="Write Message"
                    required=""
                    defaultValue={""}
                  />
                  <button type="submit" className="theme-btn">
                    Send messages <i className="fas fa-angle-double-right" />
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="contact-shapes">
            <img
              className="shape circle"
              src="assets/images/shapes/slider-dots.png"
              alt="Shape"
            />
            <img
              className="shape dots"
              src="assets/images/shapes/contact-dots.png"
              alt="Shape"
            />
            <img
              className="shape wave-line"
              src="assets/images/shapes/contact-wave-line.png"
              alt="Shape"
            />
          </div>
        </section>

        <section className="news-blog-area pt-130 pb-75 rel z-1">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="section-title text-center mb-10 wow fadeInUp delay-0-2s">
                  {/* <span className="sub-title mb-1">READ WITH US</span> */}
                  <h2><b>Our Latest Blog</b></h2>
                </div>
              </div>
            </div>
            
            <div className="row blog-card-container">
              <div className="blog-card col-lg-6 p-4">
                <div>
                  <img src="assets/images/blog/blog1.jpg" alt="Blog" className='blog-card-image ' />
                </div>
                <div className="blog-card-content">
                  <div className="blog-card-header">
                    <span className="date blog-card-date">
                      <i className="far fa-calendar-alt me-2" /> 25 March 2024
                    </span>
                    <a href="#" className="blog-card-link">Read Blog  →</a>
                  </div>
                  <h5 className='blog-card-header'>
                    <Link to="/blog-details">
                      <a>How To Choose The Best Digital Marketing Agency For Your Business.</a>
                    </Link>
                  </h5>
                  <div className="post-by blog-card-title">
                    <span>In today’s digital world, having a strong online presence is crucial for businesses. But figuring out digital marketing can be</span>
                  </div>
                </div>
              </div>
              <div className="blog-card col-lg-6 p-4">
                <div>
                  <img src="assets/images/blog/blog2.jpg" alt="Blog" className='blog-card-image ' />
                </div>
                <div className="blog-card-content">
                  <div className="blog-card-header">
                    <span className="date blog-card-date">
                      <i className="far fa-calendar-alt me-2" /> 12 June 2024
                    </span>
                    <a href="#" className="blog-card-link">Read Blog  →</a>
                  </div>
                  <h5 className='blog-card-header'>
                    <Link to="/blog-details">
                      <a>Techysquad Teams Up With Centroid Solutions</a>
                    </Link>
                  </h5>
                  <div className="post-by blog-card-title">
                    <span>Techysquad, a leading provider of Forex CRM solutions, is proud to announce its recent partnership with Centroid Solutions, a prime</span>
                  </div>
                </div>
              </div>
              <div className="blog-card col-lg-6 p-4">
                <div>
                  <img src="assets/images/blog/blog3.jpg" alt="Blog" className='blog-card-image ' />
                </div>
                <div className="blog-card-content">
                  <div className="blog-card-header">
                    <span className="date blog-card-date">
                      <i className="far fa-calendar-alt me-2" />20 July 2024
                    </span>
                    <a href="#" className="blog-card-link">Read Blog  →</a>
                  </div>
                  <h5 className='blog-card-header'>
                    <Link to="/blog-details">
                      <a>5 Marketing Tools To Use For Your Small Business</a>
                    </Link>
                  </h5>
                  <div className="post-by blog-card-title">
                    <span>In the dynamic landscape of small business marketing, using the right tools can really help. Having a strong online presence</span>
                  </div>
                </div>
              </div>

              {/* <div className="row large-gap">
             <div className="col-lg-6">
                <div className="blog-item wow fadeInUp delay-0-2s">
                  <div className="image">
                    <img src="assets/images/blog/blog1.jpg" alt="Blog" />
                  </div>
                  <div className="content">
                    <span className="date">
                      <i className="far fa-calendar-alt" /> 25 March 2022
                    </span>
                    <h4>
                      <Link to="/blog-details">
                        <a>How To Choose The Best Digital Marketing Agency For Your Business.</a>
                      </Link>
                    </h4>
                    <div className="author">

                      <div className="post-by">
                        <span>In today’s digital world, having a strong online presence is crucial for businesses. But figuring out digital marketing can be</span>

                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-lg-6">
                <div className="blog-item wow fadeInUp delay-0-4s">
                  <div className="image">
                    <img src="assets/images/blog/blog2.jpg" alt="Blog" />
                  </div>
                  <div className="content">
                    <span className="date">
                      <i className="far fa-calendar-alt" /> 27 March 2022
                    </span>
                    <h4>
                      <Link to="/blog-details">
                        <a>Techysquad Teams Up With Centroid Solutions</a>
                      </Link>
                    </h4>
                    <div className="author">
                      <div className="post-by">
                        <span>Techysquad, a leading provider of Forex CRM solutions, is proud to announce its recent partnership with Centroid Solutions, a prime</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-6">
                <div className="blog-item wow fadeInUp delay-0-2s">
                  <div className="image">
                    <img src="assets/images/blog/blog3.jpg" alt="Blog" />
                  </div>
                  <div className="content">
                    <span className="date">
                      <i className="far fa-calendar-alt" /> 25 March 2022
                    </span>
                    <h4>
                      <Link to="/blog-details">
                        <a>5 Marketing Tools To Use For Your Small Business</a>
                      </Link>
                    </h4>
                    <div className="author">
                      <div className="post-by">
                        <span>In the dynamic landscape of small business marketing, using the right tools can really help. Having a strong online presence</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </Layout>
    </Fragment>
  );
};
export default Homepage;
