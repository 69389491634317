import React from 'react';
import { Link } from "react-router-dom"
import Layout from "../layout/Layout";
import PageBanner from "../layout/PageBanner";
const Services = () => {
  return (
    <Layout>
      <PageBanner
        titleHtml={`Popular Se<span>rvices</span>`}
      // titleText="Popular Services"
      />
      <section className="statistics-area-five py-130">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="statistics-five-content mb-30 rmb-65 wow fadeInRight delay-0-2s">
                <div className="section-title mb-10">
                  <span className="sub-title style-two mb-1">
                    COMPPANY STATISTICS
                  </span>
                  <h2 className='fs-1 fw-bold'>
                    Let’s take your business <br />online

                  </h2>
                </div>
                <p>
                  We develop fast, user-friendly websites that help you reach your business goals.
                </p>
                <Link href="/about">
                  <a className="theme-btn mt-15">
                    Get Quote <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="statistics-five-image wow fadeInLeft delay-0-2s">
                <img
                  src="assets/images/about/statistics-five.png"
                  alt="Statistics"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area-one pt-130 pb-125 rel z-1">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-5">
              <div className="about-image-one bg-squire-shape rmb-85 wow fadeInUp delay-0-2s">
                <img src="assets/images/about/about-one.jpg" alt="About us" />
                <img
                  className="image-left"
                  src="assets/images/shapes/image-left.png"
                  alt="shape"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="about-content-one wow fadeInUp delay-0-4s">
                <div className="section-title mb-45">
                  <span className="sub-title  mb-1">
                    ABOUT US
                  </span>
                  <h2 className='fs-1 fw-bold'>Our Process</h2>
                </div>
                <ul className="list-style-one" style={{ padding: '0px' }}>
                  <li>
                    <div className="content">
                      <p>
                        What you can expect during the design process
                      </p>
                      <a className="theme-btn touch mt-2">
                        Know More{" "}
                        <i className="fas fa-angle-double-right" />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Statistics Five Area end */}
      {/* Working Process Area start */}
      {/* <section className="work-process-area pb-95 rel z-1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="section-title text-center mb-55 wow fadeInUp delay-0-2s">
                <span className="sub-title style-two mb-15">
                  Working Process
                </span>
                <h2>How does we works</h2>
              </div>
            </div>
          </div>
          <div className="work-process-wrap rel z-1">
            <div className="row justify-content-between">
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div className="work-process-item mt-30 wow fadeInUp delay-0-2s">
                  <div className="icon">
                    <span className="number">01</span>
                    <i className="flaticon-optimization" />
                  </div>
                  <h4>Info Gathering</h4>
                  <p>Sit amet consectetur adipiscing elit sed eiusmod tempor</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div className="work-process-item wow fadeInUp delay-0-4s">
                  <div className="icon">
                    <span className="number">02</span>
                    <i className="flaticon-link" />
                  </div>
                  <h4>Idea Planning</h4>
                  <p>Sit amet consectetur adipiscing elit sed eiusmod tempor</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div className="work-process-item mt-55 wow fadeInUp delay-0-6s">
                  <div className="icon">
                    <span className="number">03</span>
                    <i className="flaticon-data" />
                  </div>
                  <h4>Design Analysis</h4>
                  <p>Sit amet consectetur adipiscing elit sed eiusmod tempor</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div className="work-process-item mt-45 wow fadeInUp delay-0-8s">
                  <div className="icon">
                    <span className="number">04</span>
                    <i className="flaticon-star" />
                  </div>
                  <h4>Testing &amp; Lunch</h4>
                  <p>Sit amet consectetur adipiscing elit sed eiusmod tempor</p>
                </div>
              </div>
            </div>
            <div className="work-process-shape">
              <img
                src="assets/images/shapes/worp-process-step.png"
                alt="Shape"
              />
            </div>
          </div>
        </div>
      </section> */}
      {/* Working Process Area end */}
      {/* Services Area start */}
      {/* <section className="services-area-four bgc-black pt-130 pb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10">
              <div className="section-title text-center text-white mb-60 wow fadeInUp delay-0-2s">
                <span className="sub-title style-two mb-20">
                  Services We Provide
                </span>
                <h2>Popular Web Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-6">
              <div className="service-item-four wow fadeInUp delay-0-2s">
                <div className="image">
                  <img
                    src="assets/images/services/service-four1.png"
                    alt="Service"
                  />
                </div>
                <h5>Website Creation from Figma, XD or Photoshop</h5>
                <Link href="/service-details">
                  <a className="theme-btn style-three">
                    Service Details <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="service-item-four wow fadeInUp delay-0-4s">
                <div className="image">
                  <img
                    src="assets/images/services/service-four2.png"
                    alt="Service"
                  />
                </div>
                <h5>Mobile App Development and Website Design</h5>
                <Link href="/service-details">
                  <a className="theme-btn style-three">
                    Service Details <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="service-item-four wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/services/service-four3.png"
                    alt="Service"
                  />
                </div>
                <h5>eCommerce and Product Selling Development</h5>
                <Link href="/service-details">
                  <a className="theme-btn style-three">
                    Service Details <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="service-item-four wow fadeInUp delay-0-2s">
                <div className="image">
                  <img
                    src="assets/images/services/service-four4.png"
                    alt="Service"
                  />
                </div>
                <h5>Responsive Websites (UI/UX) Design</h5>
                <Link href="/service-details">
                  <a className="theme-btn style-three">
                    Service Details <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="service-item-four wow fadeInUp delay-0-4s">
                <div className="image">
                  <img
                    src="assets/images/services/service-four5.png"
                    alt="Service"
                  />
                </div>
                <h5>SEO (Search Engine Optimization)</h5>
                <Link href="/service-details">
                  <a className="theme-btn style-three">
                    Service Details <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="service-item-four wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/services/service-four6.png"
                    alt="Service"
                  />
                </div>
                <h5>Digital Product Design and Development</h5>
                <Link href="/service-details">
                  <a className="theme-btn style-three">
                    Service Details <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Services Area end */}
      {/* Pricing Area start */}
      <section className="pricing-area py-130 rel z-1">
        <div className="container">
          <div className="work-process-item mt-10 wow fadeInUp delay-0-2s">
          <span className="mb-1" style={{fontSize:'18px', fontWeight:'700', color:'#14aee5'}}>OUR BENEFITS</span>  
            <h2 className='fs-1 fw-bold mb-5'>What We Offer</h2>
            <p>We have a robust process for delivering high quality websites at an affordable price. It’s really important that you understand this process before you start working with us and that you are comfortable with it.</p>
          </div>

          <div className="mt-30 wow fadeInUp delay-0-2s">
            <h4>Web Design & Development</h4>
            <p>From full eCommerce solutions like Magento, to information sites on popular platforms like Laravel, Codeigniter, WordPress, Drupal, and many more; We can develop a custom solution to fit your exact needs.</p>
            <p>Our cost-effective solutions are created to perform optimally.
              Our agency has worked with clients from a variety of industries, to develop professional websites that add value to their businesses. Our bespoke templates ensure that your site stands out from the rest.</p>
          </div>

          <div className="mt-30 wow fadeInUp delay-0-2s">
            <h4>Design Services Prioritising User Experience</h4>
            <p>It’s surprisingly rare to come across a website that is beautifully designed and also technically sound. Our goal is to create and develop websites that excel on both fronts.
              Through design and usability, we give your customers many great reasons to explore your web pages for longer.</p>

          </div>
          <div className=" mt-30 wow fadeInUp delay-0-2s">
            <h4>Experience & Technical Expertise</h4>
            <p>Our experienced team uses their technical and creative knowledge to ensure that your website offers a faultless user experience to customers.
              From building bespoke themes, to creating bespoke extensions to add specific functionality – we are proficient at the latest web technologies, and specialise in open-source platforms like Magento, WordPress, Laravel, Codeigniter and more.</p>

          </div>

          <div className=" mt-30 wow fadeInUp delay-0-2s">
            <h4>Mobile Friendly Experiences</h4>
            <p>Users aren’t simply sitting behind keyboards anymore, they’re also looking to access online information on the go – using tablets, smartphones and other mobile devices. A successful website must be compatible with mobile platforms, or risk losing a large number of potential customers.
              Techysquad focuses on developing mobile-friendly websites, to ensure that searchers can easily find your company anytime, anywhere, on any device.</p>

          </div>

          <div className=" mt-30 wow fadeInUp delay-0-2s">
            <h4>Secure Website Hosting</h4>
            <p>It is important that your website functions properly and is accessible to users 24/7 (without technical issues). Let Techysquad set up a new website hosting solution that won t disappoint your users and search engines.</p>
            <p> Secure websites are becoming the norm as trust becomes more important to users and search engines. Contact us about implementing a security certificates that give your users peace of mind.</p>
          </div>

          <div className=" mt-30 wow fadeInUp delay-0-2s">
            <h4>Reach More Customers</h4>
            <p>Our digital marketing services can boost your online presence by driving users to your site, optimising the rate of conversions (and lowering marketing costs). Grow your organic traffic with search engine optimisation (SEO), or target users with marketing methods like email marketing and AdWords.
            </p>
            <p> These all work together to drive the right traffic to you.</p>
          </div>

          <div className="mt-30 wow fadeInUp delay-0-2s">
            <h4>Trust The Development Experts</h4>
            <p>With over a decade of industry experience to draw on, our development services help clients meet their business requirements. We value ongoing feedback from our clients to help keep our standards high, and we refuse to compromise on quality.
            </p>
            <p> For more information about our web development solutions, contact our full-service, and we’ll create the perfect solution for your needs.</p>
          </div>
        </div>
      </section>
      {/* <section className="pricing-area py-130 rel z-1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10">
              <div className="section-title text-center mb-55 wow fadeInUp delay-0-2s">
                <span className="sub-title style-two mb-20">Pricing Plan</span>
                <h2>Best Pricing Package</h2>
              </div>
            </div>
          </div>
          <div className="pricing-plan-item wow fadeInUp delay-0-2s">
            <div className="price-features">
              <h4>Basic Plan</h4>
              <ul className="list-style-two">
                <li>Landing page design (10 pages)</li>
                <li>Domin hosting provider</li>
                <li>HTML+CSS design (12 pages)</li>
                <li>Online support (24/7)</li>
                <li>Social Media Marketing</li>
                <li>WordPres development</li>
              </ul>
              <span className="badge">popular</span>
            </div>
            <div className="price-and-btn">
              <div className="content">
                <span className="price">248</span>
                <span className="save">
                  Save<span> 25%</span>
                </span>
                <Link href="/pricing">
                  <a className="theme-btn style-three">
                    Choose Package <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className="pricing-plan-item wow fadeInUp delay-0-2s">
            <div className="price-features">
              <h4>Standard Plan</h4>
              <ul className="list-style-two">
                <li>Landing page design (10 pages)</li>
                <li>Domin hosting provider</li>
                <li>HTML+CSS design (12 pages)</li>
                <li>Online support (24/7)</li>
                <li>Social Media Marketing</li>
                <li>WordPres development</li>
              </ul>
              <span className="badge">popular</span>
            </div>
            <div className="price-and-btn">
              <div className="content">
                <span className="price">452</span>
                <span className="save">
                  Save<span> 43%</span>
                </span>
                <Link href="/pricing">
                  <a className="theme-btn style-three">
                    Choose Package <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className="pricing-plan-item wow fadeInUp delay-0-2s">
            <div className="price-features">
              <h4>Premium Plan</h4>
              <ul className="list-style-two">
                <li>Landing page design (10 pages)</li>
                <li>Domin hosting provider</li>
                <li>HTML+CSS design (12 pages)</li>
                <li>Online support (24/7)</li>
                <li>Social Media Marketing</li>
                <li>WordPres development</li>
              </ul>
              <span className="badge">popular</span>
            </div>
            <div className="price-and-btn">
              <div className="content">
                <span className="price">683</span>
                <span className="save">
                  Save<span> 66%</span>
                </span>
                <Link href="/pricing">
                  <a className="theme-btn style-three">
                    Choose Package <i className="fas fa-angle-double-right" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="wave-shapes">
          <img
            className="shape one"
            src="assets/images/shapes/pricing-light1.png"
            alt="Wave Shape"
          />
          <img
            className="shape two"
            src="assets/images/shapes/pricing-light2.png"
            alt="Wave Shape"
          />
        </div>
      </section> */}
      {/* Pricing Area end */}
    </Layout>
  );
};
export default Services;
