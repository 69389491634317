import React from 'react';
import { Link } from "react-router-dom"
import Pagination from "../Pagination";
import Layout from "../layout/Layout";
import PageBanner from "../layout/PageBanner";
const Blog = () => {
  return (
    <Layout>
      <PageBanner
        titleHtml={`Blog Grid <span>View</span>`}
      // titleText="Blog Grid"
      />
      <section className="blog-grid-area py-130 rel z-1">
        <div className="container">
          <div className="row blog-card-container">
            <div className="blog-card col-lg-6 p-4 blog-grid-item">
              <div>
                <img src="assets/images/blog/blog-grid1.jpg" alt="Blog" className='blog-card-image ' />
              </div>
              <div className="blog-card-content">
                <div className="blog-card-header">
                  <span className="date blog-card-date">
                    <i className="far fa-calendar-alt me-2" /> 25 March 2022
                  </span>
                  <a href="#" className="blog-card-link">Read Blog  →</a>
                </div>
                <h5 className='blog-card-header'>
                  <Link to="/blog-details">
                    <a>How To Choose The Best Digital Marketing Agency For Your Business.</a>
                  </Link>
                </h5>
                <div className="post-by blog-card-title">
                  <span>In today’s digital world, having a strong online presence is crucial for businesses. But figuring out digital marketing can be</span>
                </div>
              </div>
            </div>
            <div className="blog-card col-lg-6 p-4 blog-grid-item">
                <div>
                  <img src="assets/images/blog/blog-grid2.jpg" alt="Blog" className='blog-card-image ' />
                </div>
                <div className="blog-card-content">
                  <div className="blog-card-header">
                    <span className="date blog-card-date">
                      <i className="far fa-calendar-alt me-2" /> 25 March 2022
                    </span>
                    <a href="#" className="blog-card-link">Read Blog  →</a>
                  </div>
                  <h5 className='blog-card-header'>
                    <Link to="/blog-details">
                      <a>How To Choose The Best Digital Marketing Agency For Your Business.</a>
                    </Link>
                  </h5>
                  <div className="post-by blog-card-title">
                    <span>In today’s digital world, having a strong online presence is crucial for businesses. But figuring out digital marketing can be</span>
                  </div>
                </div>
              </div>
            <div className="blog-card col-lg-6 p-4 blog-grid-item">
              <div>
                <img src="assets/images/blog/blog-grid4.jpg" alt="Blog" className='blog-card-image ' />
              </div>
              <div className="blog-card-content">
                <div className="blog-card-header">
                  <span className="date blog-card-date">
                    <i className="far fa-calendar-alt me-2" /> 25 March 2022
                  </span>
                  <a href="#" className="blog-card-link">Read Blog  →</a>
                </div>
                <h5 className='blog-card-header'>
                  <Link to="/blog-details">
                    <a>How To Choose The Best Digital Marketing Agency For Your Business.</a>
                  </Link>
                </h5>
                <div className="post-by blog-card-title">
                  <span>In today’s digital world, having a strong online presence is crucial for businesses. But figuring out digital marketing can be</span>
                </div>
              </div>
            </div>
            <div className="blog-card col-lg-6 p-4 blog-grid-item">
              <div>
                <img src="assets/images/blog/blog1.jpg" alt="Blog" className='blog-card-image ' />
              </div>
              <div className="blog-card-content">
                <div className="blog-card-header">
                  <span className="date blog-card-date">
                    <i className="far fa-calendar-alt me-2" /> 25 March 2022
                  </span>
                  <a href="#" className="blog-card-link">Read Blog  →</a>
                </div>
                <h5 className='blog-card-header'>
                  <Link to="/blog-details">
                    <a>How To Choose The Best Digital Marketing Agency For Your Business.</a>
                  </Link>
                </h5>
                <div className="post-by blog-card-title">
                  <span>In today’s digital world, having a strong online presence is crucial for businesses. But figuring out digital marketing can be</span>
                </div>
              </div>
            </div>
            <div className="blog-card col-lg-6 p-4 blog-grid-item">
              <div>
                <img src="assets/images/blog/blog2.jpg" alt="Blog" className='blog-card-image ' />
              </div>
              <div className="blog-card-content">
                <div className="blog-card-header">
                  <span className="date blog-card-date">
                    <i className="far fa-calendar-alt me-2" /> 27 March 2022
                  </span>
                  <a href="#" className="blog-card-link">Read Blog  →</a>
                </div>
                <h5 className='blog-card-header'>
                  <Link to="/blog-details">
                    <a>Techysquad Teams Up With Centroid Solutions</a>
                  </Link>
                </h5>
                <div className="post-by blog-card-title">
                  <span>Techysquad, a leading provider of Forex CRM solutions, is proud to announce its recent partnership with Centroid Solutions, a prime</span>
                </div>
              </div>
            </div>
            <div className="blog-card col-lg-6 p-4 blog-grid-item">
              <div>
                <img src="assets/images/blog/blog3.jpg" alt="Blog" className='blog-card-image ' />
              </div>
              <div className="blog-card-content">
                <div className="blog-card-header">
                  <span className="date blog-card-date">
                    <i className="far fa-calendar-alt me-2" />25 March 2022
                  </span>
                  <a href="#" className="blog-card-link">Read Blog  →</a>
                </div>
                <h5 className='blog-card-header'>
                  <Link to="/blog-details">
                    <a>5 Marketing Tools To Use For Your Small Business</a>
                  </Link>
                </h5>
                <div className="post-by blog-card-title">
                  <span>In the dynamic landscape of small business marketing, using the right tools can really help. Having a strong online presence</span>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-2s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid1.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      How To Choose The Best Digital Marketing Agency For Your Business.
                    </Link>
                  </h5>
                  <p>
                    In today’s digital world, having a strong online presence is crucial for businesses. But figuring out digital marketing can be overwhelming, especially if you’re not an expert. That’s where a…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-4s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid2.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      Techysquad Teams Up With Centroid Solutions
                    </Link>
                  </h5>
                  <p>
                    Techysquad, a leading provider of Forex CRM solutions, is proud to announce its recent partnership with Centroid Solutions, a prime provider of connectivity for multi-asset brokers.
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid3.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta" style={{ paddingLeft: '0' }}>
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link to="/blog-details">
                      5 Marketing Tools To Use For Your Small Business
                    </Link>
                  </h5>
                  <p>
                    In the dynamic landscape of small business marketing, using the right tools can really help. Having a strong online presence is super important because the market is tough.
                  </p>
                  <Link to="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-2s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid4.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link to="/blog-details">
                      Turn Your Website Visitors Into Buyers
                    </Link>
                  </h5>
                  <p>
                    Turning website visitors into buyers is a crucial goal for every online business. There are many factors that influence the conversion rate of a website, such as design, usability, content,…
                  </p>
                  <Link to="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-4s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid5.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link to="/blog-details">
                      How to Grow Your Business Online On a Tight Budget
                    </Link>
                  </h5>
                  <p>
                    Growing your business online on a tight budget can be challenging, but not impossible. If you are just starting a business, then you cannot afford to waste money. You should…
                  </p>
                  <Link to="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid6.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      Establishing a Strong Online Brand Identity
                    </Link>
                  </h5>
                  <p>
                    In today’s digital age, having a strong brand identity online is crucial for any business. In today’s world of social media and online shopping, it’s crucial to have a strong…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-2s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid7.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      MT5 CRM – MetaTrader 5 Trading Platform
                    </Link>
                  </h5>
                  <p>
                    Streamline Your Trading with MT5 CRM MT5 CRM is a Customer Relationship Management (CRM) system created exclusively for firms who use the MetaTrader 5 (MT5) trading platform. It enables forex…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-4s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid8.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      Techysquad Attends iFX Expo Dubai 2024
                    </Link>
                  </h5>
                  <p>
                    iFX Expo Dubai has successfully ended, and we would like to express our heartfelt gratitude for your attention and support during iFX Expo 2024 Dubai. It was a pleasure to…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      MT4 CRM – MetaTrader 4 Trading Platform
                    </Link>
                  </h5>
                  <p>
                    Streamline Your Trading with MT4 CRM In the fast-paced world of trading, efficiency is key. Every second counts when it comes to making trades and managing your customers. That’s why…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      iFX Expo Dubai 2024 | Booth 19
                    </Link>
                  </h5>
                  <p>
                    Techysquad Team at the iFX EXPO Dubai Are you planning to come to Dubai? Techysquad is coming to the iFX Expo Dubai 2024 Exciting Industry Event: iFX Expo 2024 Dubai…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>


            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a>Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      Mastering Website Development: 10 Tips for Success
                    </Link>
                  </h5>
                  <p>
                    Mastering website development is essential for any business looking to establish a strong online presence and achieve success. In this article, we will provide you with 10 valuable tips to…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      Elevate Your Brokerage with Forex CRM Multilevel IB
                    </Link>
                  </h5>
                  <p>
                    The foreign exchange market, usually referred to as Forex or FX, is the largest and most liquid financial market in the world. With an average daily trading volume of over…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      Web Design Trends for 2024 A Glimpse into Digital Evolution
                    </Link>
                  </h5>
                  <p>
                    Web design continues to evolve on an annual basis, as new technology and fresh ways of thinking replace out-dated methods. Compounding this evolution is the acceptance that a website’s presence…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      What is Forex CRM?
                    </Link>
                  </h5>
                  <p>
                    Forex CRM, also known as Customer Relationship Management (CRM), is a powerful tool that helps forex brokers manage their interactions with clients and streamline their business operations.
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>


            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      Tips to Successful Social Media Marketing
                    </Link>
                  </h5>
                  <p>
                    About 58.4% of the World’s population uses Social Media Today. That equates to 4.26 billion people around the World now using social media.
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      Introduction to Javascript
                    </Link>
                  </h5>
                  <p>
                    The worldwide web is more than 30 years old, however, the internet is even older. But, in reality, the internet and the web is getting younger by the day as…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      React Web Development
                    </Link>
                  </h5>
                  <p>
                    There are many frameworks and tools available for web development, an area that is continually expanding. React has distinguished itself as a prominent force in the world of web development…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      Improved Performance and Security with Regular PHP Updates
                    </Link>
                  </h5>
                  <p>
                    Regular updates are crucial for maintaining the performance and security of any software, and PHP is no exception. PHP, a popular server-side scripting language, powers a significant portion of the…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      SEO beginners Guide
                    </Link>
                  </h5>
                  <p>
                    SEO is the process of enhancing the performance of your site and user experience in order to increase its visibility in search engines. Ranking in organic search results is the…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>


            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      Outsource PHP Web Development
                    </Link>
                  </h5>
                  <p>
                    Are you looking for a convenient solution to reduce the time-to-market of your new web application? Outsource PHP Web Development, When budgets are tight to an experienced team nearshore or…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      Upgrade Insights What’s New in Magento 2
                    </Link>
                  </h5>
                  <p>
                    To maintain multi vendors marketplace, the Magento is the best eCommerce podium which supports the same. Now the quest arise that, what is new in Magento 2? As the scenario…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      8 Technical tips for PHP Web Development | Web Design Agency
                    </Link>
                  </h5>
                  <p>
                    Web development companies get project requests every time with different user-cases. For different projects, the development approach may vary. In general, the companies moving ahead with the latest technologies, use…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      8 key elements to get your website ranking on 1st page of Google
                    </Link>
                  </h5>
                  <p>
                    With the wild web changing frequently it is important that all businesses adapt, that is if they want to stay at the top of their game and maintain their ranking…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>
                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      Stay productive while you work from home
                    </Link>
                  </h5>
                  <p>
                    For employees who have remote work arrangements, it can be challenging to stay productive when juggling the demands of your daily job with family and home concerns. Here are 10…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="blog-grid-item wow fadeInUp delay-0-6s">
                <div className="image">
                  <img
                    src="assets/images/blog/blog-grid9.jpg"
                    alt="Blog Grid"
                  />
                </div>
                <div className="blog-content">
                  <ul className="blog-meta">
                    <li>
                      <img
                        src="assets/images/blog/blog-author.jpg"
                        alt="Author"
                      />
                      <a >Somalia D. Silva</a>
                    </li>

                  </ul>
                  <h5>
                    <Link href="/blog-details">
                      6 features that your forex platform’s CRM must have | Backoffice
                    </Link>
                  </h5>
                  <p>
                    Forex CRM software enables fx brokerages to offer clients excellent customer service and manage IBs and other partners. The best CRMs for forex brokers are more like a complete broker…
                  </p>
                  <Link href="/blog-details">
                    <a className="read-more">
                      Read More <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <ul className="pagination flex-wrap justify-content-center wow fadeInUp delay-0-2s mt-4">
          <Pagination paginationCls={".blog-grid-item"} defaultSort={6} />
        </ul>

      </section>
      {/* Blog Grid Area end */}
    </Layout>
  );
};
export default Blog;
